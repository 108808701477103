$color-background-caja: #f8f8f8;
$color-background: #3b4c4b;
$color-resalto: #a5dd72;

.container-app{
	background-color: #edf2f9;

}
:root {
  --colors-primary: #E66D1C;
  --colors-primary-contrast: white;
	--colors-secondary:#4E83EF;
	--colors-gray: #585858;
	--fieldSetColorPrimary: #281D4D;
}
@font-face {
	font-family: 'Avenir';
	src: url('assets/fonts/Avenir-Roman.ttf');
}
@font-face {
	font-family: 'Avenir Black';
	src: url('assets/fonts/Avenir-Black.ttf');
}
@font-face {
	font-family: 'Avenir Medium';
	src: url('assets/fonts/Avenir-Medium.ttf');
}
* {
  font-family: 'Avenir';
  box-sizing: border-box;
  // color: $color-background;
  margin: 0;
  padding: 0;	
}
h1, h2, h3, h4, h5, h6{
	font-family: 'Avenir Black';
	letter-spacing: 2px;
}
.swal-modal .swal-text {
	text-align: center;
}
html {
  overflow-y: scroll;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  // width: 90%;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 30px;
  overflow-y: auto;
  height: auto;
  max-height: 90%;
  min-width: 600px;
  // width: 90%;
  border-radius: 10px;
  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: 600px) {
    width:95%;
    min-width: unset;
    padding: 10px;

  }
  
}

.MuiInputBase-input{
  padding:0
}
.formControl {
  min-width: 120;
}

.flexContainer {
  background-color: $color-background-caja;
  color: #000000;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-background-caja;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-background;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-background;
}


textarea {
  border-radius: 5px;
	padding:5px;
	border-color: #DEDEDE;
  // resize: none;
}
.allow-print{
  display: none;
}
@media print{
  @page { 
    size: auto;  
    margin: 0mm;
  }
  .allow-print{
    display: block;
  }
  // .printDiv{
  //   padding:2cm;
  //   display: block; 
  //   page-break-before: auto;
  //   page-break-inside: avoid;
  // }
  tr{ 
    page-break-inside:avoid; 
    page-break-after:auto 
  }
  textarea{
    resize: none;
    min-height: 100% !important;
  }
}
