$color-background: #f8f8f8;

.filtros-base-table {
  // background-color: white;
  // padding: 15px;
  margin-bottom: 10px;
  .row-filter{
    display: flex;
    justify-content: left;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}

.table {
  // background-color: white;
  // padding: 15px;
  width: 100%;
  border-spacing: 0 10px;
  .header {
    border: hidden;
    tr{
      th{
        // padding: 10px;
      }
    }
  }
  .body {
    background-color: #ffffff;
    border: hidden;
    .container-element {
      // background-color: #ffffff;
      border: hidden;
      .element {
        border: hidden;
        // text-align: center;
        // padding: 10px;
      }
    }
  }
}
.container-navigation-table {
  margin-top: 10px;
  .button {
    background-color: $color-background;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    display: inline;
  }
  .previous-page {
  }
  .next-page {
  }
  .actual-page {
    display: inline;
    margin: 0 20px;
  }
}
.actions-table{
  @media screen and (max-width: 768px){
    position: sticky;
    right: 0;
  }
}