.container {
  overflow: hidden;
  .container-app {
		padding:20px;
    // margin-bottom: 100px;
    // width: 85%;
    // float: right;
    // padding: 0 20px;
    // @media screen and (max-width: 768px){
    //   width: 100%;
    //   padding: unset;
    // }
  }
}
